import revive_payload_client_5XGHR1qXiP from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magica_bddff53ba1d86b9d6499a8c61c27a9cd/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_wJXT3lapOt from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magica_bddff53ba1d86b9d6499a8c61c27a9cd/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_AUhOKDRdOC from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magica_bddff53ba1d86b9d6499a8c61c27a9cd/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_x7yX3Pgcmk from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.21.0_vite@5.4.1_@types+node@22.4.1_sass_d50895a7733d193c5ac5cfa0d7616831/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_uVaMvjlhVy from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magica_bddff53ba1d86b9d6499a8c61c27a9cd/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_wKUr8zUBkW from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magica_bddff53ba1d86b9d6499a8c61c27a9cd/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_bTairY6Pyy from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magica_bddff53ba1d86b9d6499a8c61c27a9cd/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_O7KWSnS0Te from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magica_bddff53ba1d86b9d6499a8c61c27a9cd/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_jSmnvxCknW from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.21.0_typescript@5.5.4_vue@3.4.38_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_aHIMoJworv from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magica_bddff53ba1d86b9d6499a8c61c27a9cd/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_d7J9FDnwtV from "/opt/build/repo/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.4_nuxt@3.12.4_@parcel+watcher@2.4.1_@type_d394f42415e383e3dc5aa0d21ab48733/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import nuxt_plugin_mmuokmx30g from "/opt/build/repo/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.4_rollup@4.21.0_webpack-sources@3.2.3/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/build/repo/.nuxt/pwa-icons-plugin.ts";
import pwa_client_MJHBJUajnc from "/opt/build/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.10.0_@vite-pwa+assets-generator@0.2.4_magicast@0.3.4_rollup@4.21.0_vit_7805fa1a31b5bd252cec858c3abf1c53/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_kjBwD4n3Dq from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.8_magicast@0.3.4_rollup@4.21.0_typescript@5.5.4_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_nRxBUrOLuB from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.0_magicast@0.3.4_rollup@4.21.0_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_IXfR5wyQPD from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.0_magicast@0.3.4_rollup@4.21.0_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import apollo_IRWTEOPleP from "/opt/build/repo/plugins/apollo.js";
import sentry_client_GoGQuZo4Et from "/opt/build/repo/plugins/sentry.client.js";
export default [
  revive_payload_client_5XGHR1qXiP,
  unhead_wJXT3lapOt,
  router_AUhOKDRdOC,
  _0_siteConfig_x7yX3Pgcmk,
  payload_client_uVaMvjlhVy,
  navigation_repaint_client_wKUr8zUBkW,
  check_outdated_build_client_bTairY6Pyy,
  chunk_reload_client_O7KWSnS0Te,
  plugin_vue3_jSmnvxCknW,
  components_plugin_KR1HBZs4kY,
  prefetch_client_aHIMoJworv,
  plugin_d7J9FDnwtV,
  nuxt_plugin_mmuokmx30g,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_MJHBJUajnc,
  plugin_kjBwD4n3Dq,
  switch_locale_path_ssr_nRxBUrOLuB,
  i18n_IXfR5wyQPD,
  apollo_IRWTEOPleP,
  sentry_client_GoGQuZo4Et
]